<template>
  <div class="check-in-details">
    <h1>{{checkIn.name || 'Loading…'}}</h1>
    <router-link to="/">« Back to Profile</router-link>

    <div v-if="checkIn.checkOutRequired" class="box check-out">
      <h2>Check Out Required</h2>
      <template v-if="checkIn.id && !checkIn.canceled">
        <p>
          Please mark yourself safe when you are off the trail
        </p>

        <p v-if="checkOutError" class="err">{{ checkOutError }}</p>

        <div class="model controls">
          <button :class="{danger: !checkOutInProcess && !checkIn.canceled}" @click="doCheckOut">Check Out</button>
        </div>
      </template>
      <template v-else>
        <p>Checked Out</p>
        <status-animation :status="animStatus" />
      </template>
    </div>
    
    <loading-spinner v-if="loading" />
  </div>
</template>

<style lang="scss">
.check-out {
  padding: 1rem;
  margin: 0 auto;
  max-width: 500px;
  
  h2 {
    font-size: 1.25rem;
  }
  
  .err {
    color: var(--error-color);
    margin: 0;
  }
}
</style>

<script>
import { nextTick } from 'vue'
import StatusAnimation from 'Shared/components/status-animation'
export default {
  name: 'CheckInDetails',
  components: { StatusAnimation },
  
  props: {
    checkInId: {
      type: String,
      required: true,
    }
  },
  
  data() {
    return {
      loading: false,
      showCheckOutBox: false,
      checkOutInProcess: false,
      checkOutError: null,
      animStatus: 'indeterminate',
      checkIn: {}
    }
  },
  
  mounted() {
    this.fetchCheckIn();
  },
  
  methods: {
    async fetchCheckIn() {
      this.loading = true;
      try {
        const r = await this.axios.request({url: `account/check_ins/${this.checkInId}`});
        this.checkIn = r.data;
      }
      catch (e) {
        console.error("Error fetching check in", e);
      }
      this.loading = false;
      this.showCheckOutBox = this.checkIn.checkOutRequired;
      this.animStatus = this.checkIn.checkOutRequired && this.checkIn.canceled ? 'success' : 'indeterminate';
    },
    
    doCheckOut() {
      this.loading = true;
      this.checkOutInProcess = true;
      this.axios.request({
        method: 'post',
        url: `account/check_ins/${this.checkInId}/cancel`,
        data: { 
        
        }
      })
      .catch(err=> {
        this.loading = false;
        this.checkOutInProcess = false;
        this.checkOutError = 'Could not check out';
      })
      .then(r=> {
        this.loading = false;
        this.checkOutInProcess = false;
        
        Object.assign(this.checkIn, r.data);
        nextTick(()=> this.animStatus = 'success' );
      });
    
    }
  }
}
</script>