<template>
  <main class="profile">
    <header class="main">
      <div class="profile">
        <loading-spinner v-if="loading" />
        <div class="avatar-wrapper">
          <figure class="avatar" :class="{loading: avatarUploadProgress, done: avatarUploadProgress >= 100}" @click="askForAvatar">
            <svg viewBox="0 0 36 36">
              <path
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#f00"
                stroke-width="1"
                :stroke-dasharray="`${avatarUploadProgress}, 100`"
              />
            </svg>
            <img alt="profile" :src="avatarUrl">
          </figure>
          <span class="avatar-tip">Tap to set</span>
        </div>
        
        <div class="info"> 
          <h1 class="name" @click="showProfileEdit">
            <span v-if="account">{{ account.fn }} {{ account.ln }}</span>
          </h1>
          <h3 class="detail">
            <span class="completion-title">Profile Completion</span>
            <span class="completion"><progress max="1" :value="completion" /></span>
          </h3>
        </div>
      </div>
    </header>

    <form class="profile-attrs" @click="cancelEvent">
      <fieldset>
        <div class="form-row">
          <label>First Name</label>
          <input type="text" v-model="accountChanges.fn">
        </div>
        <div class="form-row">
          <label>Last Name</label>
          <input type="text" v-model="accountChanges.ln">
        </div>

        <div class="form-row ">
            <label>Gender</label>
          
            <segmented-button ref="g" :class="{err: hasErr('g')}" v-model="accountChanges.g" :choices="[
              {label: 'M', value: 'm', title: 'Male'},
              {label: 'F', value: 'f', title: 'Female'},
              {label: 'O', value: 'i', title: 'Other / Prefer not to say'},
            ]" />
          
            <span class="note">{{ genderExplainer }}</span>
        </div>
        
        <div class="form-row">
          <label>Date of Birth</label>
          <input type="date" v-model="accountChanges.dob">
        </div>
        
      </fieldset>
      <fieldset>
        <h2>Contact Info</h2>
        <div class="form-row">
          <label>Phone</label>
          <input type="tel" v-model="accountChanges.phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="e.g. 602-346-0554">
        </div>
        <div class="form-row big-label">
          <label>Text Message Okay?</label>
          <i class="flexible-space" />
          <segmented-button ref="textOkayButton" v-model="accountChanges.textConsent" :choices="[
            {label: 'No', value: false, title: 'Please do not text me'},
            {label: 'Okay', value: true, title: 'Okay to text'},
          ]" :transform="v=>Boolean(v)"/>
        </div>

        <div class="note">Used to ensure safety— We will not text for promotional reasons at any time.</div>
        
        <div class="form-row">
          <label>Emergency Name</label>
          <input type="text" v-model="accountChanges.emergencyName">
        </div>
        <div class="form-row">
          <label>Emergency Phone</label>
          <input type="text" v-model="accountChanges.emergencyPhone">
        </div>
        
        
      </fieldset>
      
      <div class="model controls">
        <button @click="doSendProfileInfo">Save</button>
      </div>
    </form>

    <input ref="fileInput" style="display:none" type="file" @change="handleNewAvatarFile">
  </main>
</template>
<!--
    t.string "email"
    t.date "dob"
    t.integer "age"
    t.string "shirt_size"
    t.jsonb "avatar_data"
    t.string "address"
    t.string "city"
    t.string "locality"
    t.string "country"
    t.string "postal"
    t.string "emergency_name"
    t.string "emergency_phone"
    t.string "scope"
 -->

<!-- Much of the CSS for this component is defined in the profile component -->


<script>
export default {
  name: 'ProfileEdit',
  components: {  },
  
  data() {
    return {
      loading: false,
      account: undefined,
      accountChanges: {},
      
      avatarFileBlobURL: null,
      avatarUploadProgress: 0,
    }
  },
  
  computed: {
    avatarUrl() {
      if (this.avatarFileBlobURL)
        return this.avatarFileBlobURL;
      
      if (!this.account || !this.account.a) 
        return 'https://www.gravatar.com/avatar/?s=128&d=mp&f=y';

      return this.account.a;
    },
    
    genderExplainer() {
      let g = this.accountChanges.g || 'undefined'
      switch (g.toLowerCase()) {
        case 'm': return 'Male';
        case 'f': return 'Female';
        case 'undefined': return '';
        default: return 'Other/Prefer not to say';
      }
    },
    
    completion() {
      let tot=0, count=0;
      // let a = this.accountChanges;
      if (this.accountChanges.a?.match(/thumbnail/)) tot++; count++; //avatar is a thumbnail
      
      if (this.accountChanges.fn?.length) tot++; count++;
      if (this.accountChanges.ln?.length) tot++; count++;
      if (this.accountChanges.g?.length) tot++; count++;
      if (this.accountChanges.dob) tot++; count++;

      if (this.accountChanges.shirt_size) tot++; count++;

      if (this.accountChanges.phone?.length) tot++; count++;
      // if (this.accountChanges.address?.length) tot++; count++;
      // if (this.accountChanges.city?.length) tot++; count++;
      // if (this.accountChanges.locality?.length) tot++; count++;
      // if (this.accountChanges.country?.length) tot++; count++;
      // if (this.accountChanges.postal?.length) tot++; count++;
      if (this.accountChanges.emergencyName?.length) tot++; count++;
      if (this.accountChanges.emergencyPhone?.length) tot++; count++;
      
      console.debug(`account completion ${tot}/${count}`);
      return tot/count;
    }
  },
  
  mounted() {
    this.fetchAccount();
  },
  
  methods: {
    async fetchAccount() {
      this.loading = true;
      try {
        const r = await this.axios.request({url: 'account'});
        this.account = r.data;
        Object.assign(this.accountChanges, this.account);
      }
      catch (e) {
        console.error("Error fetching account", e);
      }
      this.loading = false;
    },
    
    hasErr(x) { 
      //@TODO: validate
      return false 
    },
    
    askForAvatar(e) {
      e && e.preventDefault();
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    
    handleNewAvatarFile(e) {
      const f = e.target.files[0];

      // Immediately show the image given. It's quick and from-memory.
      this.avatarFileBlobURL = URL.createObjectURL(f);
        
      // We can't set an avatar image until the account exists,
      // so we'll always have an ID to reference
      if (this.account.id) {
        // PUT the image as FormData
        const data = new FormData();
        data.append('account[avatar]', f);
        
        this.axios.request({
          method: 'put',
          url: `account`,
          headers: {
            'Accept': 'application/json',
          },

          data,
          
          onUploadProgress: (p) => {
            const totalLength = p.lengthComputable ? p.total : p.target.getResponseHeader('content-length') || p.target.getResponseHeader('x-decompressed-content-length');
            console.log("onUploadProgress", totalLength, p);
            if (totalLength !== null) {
              this.avatarUploadProgress = (Math.round( (p.loaded * 100) / totalLength ));
            }
          }
        })
        .then(r=> {
          console.log("info update resp", r.data);
          // Object.assign(this.account, r.data); // yikes.
          this.avatarUploadProgress = 100;
          this.loading = false;
        })
        .catch(e => {
          console.error("err", e);
          nextTick(() => {
            this.avatarUploadProgress = null;
            this.loading = false;
          })
        })
      }
      else {
        // Save image data to post for creation
        //
        // const fr = new FileReader();
        // fr.onload = () => this.avatarFileDataURL = fr.result;
        // fr.readAsDataURL(f);
      }
    },
    
    doSendProfileInfo(e) {
      e && e.preventDefault();
      
      this.loading = true;
      this.axios.request({
        method: 'put',
        url: `account`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: { account: this.accountChanges }
      })
      .then(r=> {
        console.log("info update resp", r.data);
        Object.assign(this.account, r.data); // yikes?
        this.loading = false;
        // if (!this.account.emergencyPhone || this.account.emergencyPhone.length < 1)
        //   shakeElement(this.$refs.deetsBox);
      })
      .catch(e => {
        console.error("err", e);
        this.loading = false;
        shakeElement(this.$refs.deetsBox);
      })
    },
    
    cancelEvent(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  }
  
}
</script>